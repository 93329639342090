import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web-client/app/_providers/emotion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web-client/app/_providers/react-query.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web-client/app/_providers/snackbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web-client/app/_providers/theme.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web-client/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Favicon"] */ "/app/apps/web-client/utils/favicon.tsx");

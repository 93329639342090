"use client"

import { isDevelopment } from "@/utils/env"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { SessionProvider } from "next-auth/react"
import type { ReactNode } from "react"
import { trpc } from "../_trpc/client"

function ReactQuery({ children }: { children: ReactNode }) {
  return (
    <SessionProvider>
      {isDevelopment() && <ReactQueryDevtools initialIsOpen={false} />}
      {children}
    </SessionProvider>
  )
}

export default trpc.withTRPC(ReactQuery) as (params: {
  children: ReactNode
}) => JSX.Element

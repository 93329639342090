"use client"

import { styled, useTheme } from "@mui/material/styles"
import { MaterialDesignContent, SnackbarProvider } from "notistack"
import { type JSXElementConstructor, type ReactNode, useMemo } from "react"

export default function RootSnackbarProvider({
  children,
}: {
  children: ReactNode
}) {
  const theme = useTheme()

  const StyledMaterialDesignContent = useMemo(
    () =>
      styled(MaterialDesignContent)(() => ({
        "&.notistack-MuiContent-success": {
          backgroundColor: theme.palette.success.main,
        },
        "&.notistack-MuiContent-error": {
          backgroundColor: theme.palette.error.main,
        },
        "&.notistack-MuiContent-warning": {
          backgroundColor: theme.palette.warning.main,
        },
        "&.notistack-MuiContent-info": {
          backgroundColor: theme.palette.info.main,
        },
        "&.notistack-MuiContent-default": {
          backgroundColor: theme.palette.info.main,
        },
      })),
    [theme],
    // biome-ignore lint/suspicious/noExplicitAny: fix to satisfy type error in SnackbarProvider
  ) as JSXElementConstructor<any>

  return (
    <SnackbarProvider
      maxSnack={3}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        default: StyledMaterialDesignContent,
      }}
    >
      {children}
    </SnackbarProvider>
  )
}
